"use strict";

/*global
 window, document, console, require, Foundation
 */

document.addEventListener('DOMContentLoaded', () => {
    //$(document).foundation();

    const form = $('#contact-form');
    let resultId = false;

    $('.choices span').mouseenter(function(){
        const answer = $(this).data('answer');

        $(this).parents('.question').find('.choose-btn[data-answer="'+ answer +'"]').addClass('hover');
    });

    $('.choices span').mouseleave(function(){
        const answer = $(this).data('answer');

        $(this).parents('.question').find('.choose-btn[data-answer="'+ answer +'"]').removeClass('hover');
    });

    $('.choices span').click(function(){
        const answer = $(this).data('answer');

        $(this).parents('.question').find('.choose-btn[data-answer="'+ answer +'"]').click();
    });

    $('.continue-btn').click(function(){
        const $hide = $('#'+ $(this).data('hide') );
        const $show = $('#'+ $(this).data('show') );

        $hide.fadeOut(function(){
            $show.fadeIn();
            $('body').attr('data-page', $(this).data('show'));

            $('html, body').animate({
                scrollTop: $show.offset().top
            }, 300);
        });
    });

    form.on('change', 'input[type="text"], input[type="email"]', function(){
        $(this).parent().removeClass('error');
    });

    $('body').on('click', '.open-rules', function(){
        $('.rules').slideToggle(200);

        $('html, body').animate({
            scrollTop: $('.rules').offset().top
        }, 300);
    });

    $('body').on('click', '.close-rules', function(){
        $('.rules').slideUp(200);
    });

    form.on('submit', function(e) {
        e.preventDefault();
        $('input[type="submit"]').attr('disabled', true);

        var valid = form.validateForm({
            nimi: {
                required: '',
            },
            email: {
                required: '',
                email: ''
            }
        });

        if(valid){
            $('.form-error').hide();
            sendFormViaAjax(form);
        } else {
            $('.form-error').fadeIn(200);
            $('input[type="submit"]').attr('disabled', false);
            $('html, body').animate({
                scrollTop: $('form .error').offset().top - 80
            }, 300);
        }
    });

    function sendFormViaAjax() {
        $.ajax({
            url: '/php/submit.php',
            type: 'POST',
            cache: 'false',
            data: form.serialize()
        }).always(function (data) {
            $('#contact-form').fadeOut(function(){
                $('#thanks').fadeIn();
            });
        });
    }

    let choices = {};

    $('body').on('click', '.choose-btn:not(.disabled)', function(){
        const button = $(this);
        const parent = $(this).parents('.question');
        let id = parent.data('question');

        button.addClass('disabled');

        choices[id] = $(this).data('answer');

        parent.find('.selected').removeClass('selected');
        $(this).addClass('selected');

        const iframe = document.querySelector( '[data-question="'+ id +'"] iframe');

        if (iframe) {
            const player = new Vimeo.Player(iframe);
            player.pause();
        }

        parent.fadeOut(function(){
            button.removeClass('disabled');

            if (id === 10) {
                getResult();
                return;
            }

            id = id + 1;

            $('.question[data-question="'+ id +'"] p').removeClass('hidden');
            $('.question[data-question="'+ id +'"]').fadeIn(function(){
                $('html, body').animate({
                    scrollTop: $('.question[data-question="'+ id +'"] .player').offset().top - 20
                }, 300);
            });
        });
    });

    $('body').on('click', '.back-btn', function(){
        const parent = $(this).parents('.question');
        let id = parent.data('question');

        const iframe = document.querySelector( '[data-question="'+ id +'"] iframe');

        if (iframe) {
            const player = new Vimeo.Player(iframe);
            player.pause();
        }

        parent.fadeOut(function(){
            id = id - 1;

            $('.question[data-question="'+ id +'"] p').removeClass('hidden');
            $('.question[data-question="'+ id +'"]').fadeIn();
        });
    });

    $('body').on('click', '.reload-btn', function(){
        location.reload();
    });

    function getResult() {

        $.ajax({
            url: '/php/result.php',
            type: 'POST',
            cache: 'false',
            data: choices
        }).always(function (data) {
            data = $.parseJSON(data);

            resultId = data.id;
            $('input[name="vastausid"]').val(resultId);

            $('.result-count span').text(data.count);
            $('.result-title').html(data.title);
            $('.result-text').html(data.text);
            $('.result-video .player:not(.result-'+ data.count +')').remove();

            $('#quiz').hide();

            $('#result').fadeIn(400, function(){

                const iframe = document.querySelector('.result-video iframe');
                const player = new Vimeo.Player(iframe);
                player.play();
            });

            $('html, body').animate({
                scrollTop: $('body').offset().top - 20
            }, 300);
        });
    }
});

import './form-validator';